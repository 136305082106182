// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import React from "react";
import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";
import { SidebarWrapper } from "../components/sidebar-wrapper";
import backCover from "../images/back-cover.png";

const BackCoverPage = () => (
    <Layout>
        <PageInfo title="Book - Back Cover" />
        <SidebarWrapper main={<>
            <h1>The Back Cover</h1>
            <p>
                Here's what it says on the back cover of the book:
            </p>
            <blockquote>
                <p>
                    <strong>All of JavaScript's newest features, in depth, made easy to understand</strong>
                </p>
                <p>
                    JavaScript is a rapidly changing language and it can be challenging for experienced
                    developers to keep up with all the new toys being added. This book explores the newest
                    features of the world's most popular programming language while also showing readers how
                    to track what's coming next. After setting the stage by covering who manages the process
                    of improving JavaScript, how new features get introduced, terminology, and a high-level
                    overview of new features, it details each new or updated item in depth, with example uses,
                    possible pitfalls, and expert recommendations for updating old habits in light of new features.
                </p>
                <p>
                    JavaScript: The New Toys:
                </p>
                <ul>
                    <li>
                        Covers all the additions to JavaScript in ES2015-ES2020 plus a preview of what's coming next
                    </li>
                    <li>
                        Explores the latest syntax: nullish coalescing, optional
                        chaining, <code>let</code> and <code>const</code>, <code>class</code> syntax, private
                        methods, private fields, <code>new.target</code>, numeric separators, BigInt,
                        destructuring, default parameters, arrow functions, <code>async</code> functions, <code>await</code>, generator
                        functions, <code>...</code> (rest and spread), template literals, binary and octal
                        literals, <code>**</code> (exponentiation), computed property/method
                        names, <code>for-of</code>, <code>for-await-of</code>, shorthand properties, and more
                    </li>
                    <li>
                        Details the new features and patterns: modules, promises, iteration, generators, Symbol,
                        Proxy, reflection, typed arrays, Atomics, shared memory, WeakMap, and WeakSet to name just a few
                    </li>
                    <li>
                        Highlights common pitfalls and explains how to avoid them
                    </li>
                    <li>
                        Shows how to follow&nbsp;&mdash; and participate in&nbsp;&mdash; the improvements process
                    </li>
                    <li>
                        Explains how to use new features even before they're widely supported
                    </li>
                </ul>
                <p>
                    Wrox Professional guides are written by working developers to address everyday needs.
                    They provide examples, practical solutions, and expert education in new technologies, all
                    designed to help experienced programmers do a better job.
                </p>
                <p>
                    <strong>About the author</strong>
                </p>
                <p>
                    T.J. Crowder is a software engineer with ~30 years of experience, including over 15 years of
                    professional work in JavaScript. He runs Farsight Software, a software contracting and product
                    company. He's often found helping people on Stack Overflow, where he's a top 10 all-time
                    contributor and <em>the</em> top JavaScript contributor. When not working or writing,
                    he...no, sorry, he's always working or writing&nbsp;&mdash; or spending time with his
                    wonderful, supportive wife and fantastic son.
                </p>
            </blockquote>
            <img src={backCover} role="presentation" />
        </>}/>
    </Layout>
);

export default BackCoverPage;
